import React from 'react'
import { Link } from 'react-router-dom'

import './Home.css'
import Icon from '../../images/icon.svg'

export default function Home() {
  return (
    <div className='home'>
      <img src={Icon} alt='GARUDEYE' className='home-icon' />
      <h1 className='home-title'>GARUDEYE</h1>
      <h2 className='coming-soon'>COMING SOON</h2>
      <div className='notify-me'>
        <label>
          <input
            type='email'
            placeholder='EMAIL ADDRESS'
            className='email'
          ></input>
        </label>
        <Link to='/'>
          <button className='btn'>NOTIFY ME!</button>
        </Link>
      </div>
    </div>
  )
}
